<div
  class="tw-fixed tw-z-50 tw-w-full tw-flex tw-justify-center tw-opacity-0 focus-within:tw-opacity-100 tw-pointer-events-none focus-within:tw-pointer-events-auto"
>
  <nav class="tw-bg-background-alt3 tw-rounded-md tw-rounded-t-none tw-py-2 tw-text-alt2">
    <a
      bitLink
      class="tw-mx-6 focus-visible:before:!tw-ring-0"
      [fragment]="mainContentId"
      [routerLink]="[]"
      (click)="focusMainContent()"
      linkType="light"
      >{{ "skipToContent" | i18n }}</a
    >
  </nav>
</div>
<div class="tw-flex tw-w-full">
  <aside
    [ngStyle]="
      variant === 'secondary' && {
        '--color-background-alt3': 'var(--color-background-alt3-ac)',
        '--color-background-alt4': 'var(--color-background-alt4-ac)',
        '--color-primary-300': 'var(--color-primary-300-ac)'
      }
    "
    class="tw-sticky tw-inset-y-0 tw-h-screen tw-w-60 tw-overflow-auto tw-bg-background-alt3"
  >
    <ng-content select="[slot=sidebar]"></ng-content>
  </aside>
  <main
    [id]="mainContentId"
    tabindex="-1"
    class="tw-overflow-auto tw-min-w-0 tw-flex-1 tw-bg-background tw-p-6"
  >
    <ng-content></ng-content>
  </main>
</div>
